import React from "react"

export const createLocaleTextGetter = languageCode => {
  const languages = [languageCode, "en", "sv"] // last language in array is default;

  const localize = value => {
    if (Array.isArray(value)) {
      return value.map(v => localize(v, languages))
    } else if (typeof value == "object" && !!value) {
      if (
        value?._type === "textBlock" ||
        value?._type === "translation" ||
        value?._type === "richTextTranslation"
      ) {
        const language = languages.find(
          lang => !!value[lang] && value[lang]?.length
        )
        return value[language]
      }
      return Object.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages)
        return result
      }, {})
    }
    return value
  }
  return localize
}

function localize(Component) {
  return class Localize extends React.Component {
    constructor(props) {
      super(props)
      this.getLocalizedContent = createLocaleTextGetter(
        props.pageContext.locale
      )
    }
    render() {
      return (
        <Component
          {...this.props}
          data={this.getLocalizedContent(this.props.data)}
        />
      )
    }
  }
}

export default localize
