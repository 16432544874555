import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { Layout } from "../components/Layout"
import { BlockContent } from "../components/BlockContent"
import localize, { createLocaleTextGetter } from "../../localize"
import { StockContext } from "../components/StockContext"
import { LocaleContext } from "../components/LocaleContext"
import { ProductsContext } from "../components/ProductsContext"

export const query = graphql`
  fragment translatableRichText on SanityRichTextTranslation {
    _type
    en: _rawEn
    sv: _rawSv
    fi: _rawFi
    nb: _rawNb
    da: _rawDa
  }
  fragment translatable on SanityTranslation {
    _type
    en
    sv
    fi
    nb
    da
  }
  fragment filterFragment on SanityFilter {
    __typename
    _key
    variant
    alcoholicContent
    appPlatform
    appVersion {
      operator
      version
    }
    endDate
    endTime
    startDate
    startTime
    membership
    types
    venues {
      id
    }
  }

  fragment pageBlocksFragment on SanityPage {
    blocks {
      __typename
      ...videoFragment
      ...carouselFragment
      ...promoFragment
      ...verticalGroupFragment
      ...textBlockFragment
      ...richTextBlockFragment
      ...imageBlockFragment
      ...overlineFragment
    }
  }

  query ($regex: String!) {
    sanityPage(_id: { regex: $regex }) {
      _id
      name
      ...pageBlocksFragment
    }
    allStock {
      nodes {
        product
        venues
      }
    }
    allProduct {
      nodes {
        product {
          id
          title {
            _type
            en
            sv
            fi
            nb
            da
          }
          subtitle {
            _type
            en
            sv
            fi
            nb
            da
          }
        }
      }
    }
  }
`

const EditorialPage = ({ pageContext, data, location }) => {
  const getLocalizedContent = createLocaleTextGetter(pageContext.locale)
  const { sanityPage, allStock, allProduct } = getLocalizedContent(data)

  return (
    <LocaleContext.Provider value={pageContext.locale}>
      <StockContext.Provider value={allStock}>
        <ProductsContext.Provider value={allProduct.nodes.map(p => p.product)}>
          <Layout location={location}>
            <BlockContent
              locale={pageContext.locale}
              blocks={sanityPage.blocks}
              blockWrapper={({ children }) => <div>{children}</div>}
            />
          </Layout>
        </ProductsContext.Provider>
      </StockContext.Provider>
    </LocaleContext.Provider>
  )
}

export default EditorialPage
